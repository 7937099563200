<template>
  <div class="section-wrapper">
    <div class="sub-section-wrapper">
      <breadcrumbs :content="breadcrumbsContents"></breadcrumbs>
      <div class="wrapper-box">
        <div class="header-box">
          <div class="left">
            <div class="title">{{ current.title }}</div>
            <div class="description">{{ current.description }}</div>
            <div class="button-wrapper">
              <a class="button"  @click="()=>modalSw=true">咨询报价</a>
            </div>
          </div>
          <div class="right">
            <img :src="urlTrans(current.imgUrl)" alt="" />
          </div>
        </div>
        <div class="content-box">
          <div class="html-box" v-html="current.content"></div>
        </div>
        <div class="relative-wrapper">
          <div class="title">相关内容：</div>
          <div class="">
            <div class="relative-box">
              <div
                class="ele-item"
                v-for="(ele, index) in relative"
                :key="index"
              >
                <div class="ele-inner-wrapper" @click="linkTo('/service/'+ele.id)">
                  <div class="ele-a">
                    <img :src="urlTrans(ele.url[0])" alt="" />
                  </div>
                  <div class="ele-b">
                    <div class="b-des">
                      <div class="b-des-title">
                        {{ ele.title }}
                      </div>
                    </div>
                  </div>
                  <div class="ele-c">
                    <div class="b-link">
                      <p>查看详情 ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal v-model="modalSw" width="600" :styles="{top: '200px'}">
            <template #header>
                <p>
                    业务联系方式
                </p>
            </template>
            <div class="contact-div">
                <p>
                  <span class="icon"><Icon type="ios-call-outline" :size="24"/></span>
                  <label for="">电话：</label>
                  <span class="contact-value">{{current.phone?current.phone:'021-56556312'}}</span>
                  </p>
                <p>
                  <span class="icon"><Icon type="ios-mail-outline" :size="24"/></span>
                  <label for="">邮箱：</label>
                  <a class="contact-value" :href="'mailto:'+(current.email?current.email:'srcc@chinasrcc.com')">{{current.email?current.email:'srcc@chinasrcc.com'}}</a>
                  </p>
            </div>
            <template #footer>
                <Button @click="()=>modalSw=false">关闭</Button>
            </template>
        </Modal>
    </div>
  </div>
</template>
<script>
import breadcrumbs from "@/components/Libs/Breadcrumbs.vue";
export default {
  name: "indexContent",
  components: {
    breadcrumbs,
  },
  props: {
    serviceId: {
      type: String,
      required: true,
      default: "1",
    },
  },
  data() {
    return {
      current: {
        // imgUrl: process.env.BASE_URL + "img/index/risk.jpg",
        // theSrc: "/risk_nav",
        // title: "检测试验",
        // description:
        //   "轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。",
        // html:"等离子体发射光谱分析碳钢、铸铁、不锈钢、铝合金、锌合金、铜合金、镍基合金、铁矿石、孕育剂中常规和微量元素的检测，每个可测元素都有多个不同灵敏度的波长以供选择。对Mn、P、Cr、Ni、Cu、Mo、V、Al、Ti等元素一般检测限从0.002％-5％。原子吸收光谱分析碳钢、铸铁、不锈钢、铝合金、锌合金、铜合金、镍基合金、铁矿石、孕育剂中常规和微量元素的检测。氢氧氮分析钢铁中N、H、O的含量的测定：H： 0.1-250ppm ；N： 0.05-30000ppm；O：0.05-50000ppm。红外碳硫分析各种类型金属材料中的碳和硫的检测：C：0.00006-6.0%；S：0.00006-0.35%。直读光谱分析标准样块覆盖了白口铸铁、中低合金钢、不锈钢、高速钢、低硅铝合金、高硅铝合金、铝黄铜、铅黄铜和TC4钛合金，最低检测下限可达0.001％。"
      },
      relative: [
        // {
        //   imgUrl: process.env.BASE_URL + "img/index/risk.jpg",
        //   theSrc: "/risk_nav",
        //   title: "检测试验",
        //   description:
        //     "轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。",
        // },
        // {
        //   imgUrl: process.env.BASE_URL + "img/index/report.jpg",
        //   theSrc: "/conclusion/report_l",
        //   title: "检验评估",
        //   description:
        //     "城市轨道交通运营安全评价；车辆服役安全评估；覆盖轨道交通系统从规划、设计、制造、建设、安装、调试、试运行、运营及维护阶段全生命周期的符合性评估。",
        // },
        // {
        //   imgUrl: process.env.BASE_URL + "img/index/accident.jpg",
        //   theSrc: "/conclusion/accident_l",
        //   title: "认证服务",
        //   description:
        //     "陆地交通设备领域自愿性产品认证（含安全认证）、城轨装备认证、服务认证、质量体系认证；轨道交通领域出口产品认证。",
        // },
      ],
      modalSw:false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.spin = true;
      this.$axios
        .post(this.$url + "/content", {
          serviceId: this.serviceId,
          askData: "services",
        })
        .then((response) => {
          this.current = response.data.current;
          this.relative = response.data.relative;
          this.spin = false;
        });
    },
    linkTo: function (value) {
      location.href = value;
    },
    urlTrans(url) {
      // return url;
      if (url) {
        return this.$fileUrlFacWithoutRight(url);
      } else {
        return "";
      }
    },
  },
  computed: {
    breadcrumbsContents: function () {
      return [
        { text: "首页", url: "/" },
        { text: "我们的服务", url: "/services_list/all" },
        { text: this.current.title, url: "/service/" + this.serviceId },
      ];
    },
  },
  mounted() {},
};
</script>
<style scoped>
.section-wrapper {
  width: 100%;
}

.sub-section-wrapper {
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.wrapper-box {
  padding: 0 40px;
}
.header-box {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 10px 0 50px;
}

.left {
  width: 50%;
}

.right {
  width: 40%;
}

.right img {
  width: 100%;
}

.left .title {
  font-size: 30px;
  font-weight: 700;
  line-height: 90px;
  border-bottom: 1px solid #ddd;
  color: #00508e;
  font-family: 微软雅黑;
}

.left .description {
  padding: 50px 0;
  color: #666;
  font-size: 14px;
  line-height: 28px;
  text-indent: 28px;
}

.button {
  padding: 15px 60px;
  border: 1px solid #ddd;
  font-size: 16px;
  color: #999;
  cursor: pointer;
  border-radius: 1px;
}

.button:hover {
  background-color: #00508e;
  color: #fff;
  border: none;
}

.content-box {
  padding: 50px 1px;
  font-size: 16px;
  line-height: 30px;
  color: #333;
}

.relative-wrapper .title {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #444;
}

.relative-box {
  display: flex;
  display: -webkit-flex;
  padding: 30px 0;
  overflow: hidden;
  flex-wrap: wrap;
}

.ele-item {
  width: 25%;
  padding: 15px 15px;
}

.ele-inner-wrapper {
  border: 1px solid #eee;
  cursor: pointer;
  padding: 10px;
}

.ele-a {
  overflow: hidden;
}

@keyframes it {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.ele-a img:hover {
  animation: it 10s ease 0s forwards;
}

.ele-a {
  text-align: center;
}

.ele-a img {
  width: 100%;
  height: 150px;
  display: block;
}

.b-des {
  padding: 0 10px;
}

.b-des-title {
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, Tahoma, "Microsoft YaHei",
    "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei",
    sans-serif;
  outline: none;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1.333;
  min-height: 50px;
}

.ele-b {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ele-c {
  padding: 0 10px;
}

.b-link {
  font-size: 14px;
  color: rgb(192, 0, 0);
  line-height: 184%;
}
.contact-div p{
  line-height: 3;
  padding-left:150px;
}

.contact-div span.icon{
  margin-right: 10px;
  position: relative;
  top:2px;
}
.contact-div label{
  font-size: 14px;
  color:#999;
}
.contact-div .contact-value{
  font-size: 16px;
  font-weight: 600;
  color:#333;
}
</style>